/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, NavbarBrand } from "reactstrap";
import { Link } from "react-router-dom";
// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container style={{alignItems: 'center'}}>
          <div className="copyright float-left">
            <a href="/TerminosCondiciones">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Términos y condiciones</h6>
            </a>
            <a href="/PoliticasDatosPersonales">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Política de Tratamiento de Datos Personales</h6>
            </a>
            <a href="/PoliticasDerechosAutor">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Politícas de derechos de autor</h6>
            </a>
            <a href="/CertificadoAccesibilidad">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Certificado de Accesibilidad</h6>
            </a>
            <a href="/PoliticasPrivacidadWeb">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Políticas de Privacidad web</h6>
            </a>
            <a href="/MapaSitio">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Mapa del Sitio</h6>
            </a>
            <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Telefonos: +57 (2) 330 36 84</h6>
            <a href="/#contacto">
              <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer" }}>Canales de Atencion al publico</h6>
            </a>
          </div>
          <div className="copyright" id="copyright" >
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img style={{ backgroundColor: 'white', borderRadius: "15px" }} src={require("assets/img/Notaria/logoNot.png")} alt="Logo de la notaria 21 de Cali" width="110px"></img>
            </NavbarBrand>
          </div>
        </Container>
        <div style={{ backgroundColor: '#474747', width: '100%', height: '40px', display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
          <NavbarBrand to="/" tag={Link} id="navbar-brand">
            <img style={{ backgroundColor: 'white', borderRadius: "15px" }} src={require("assets/img/facebook.png")} alt="Logo Facebook" width="40px"></img>
          </NavbarBrand>
          <NavbarBrand to="/" tag={Link} id="navbar-brand">
            <img style={{ backgroundColor: 'white', borderRadius: "15px" }} src={require("assets/img/instagram.jpg")} alt="Logo Instagram" width="40px"></img>
          </NavbarBrand>
          <NavbarBrand to="/" tag={Link} id="navbar-brand">
            <img style={{ backgroundColor: 'white', borderRadius: "15px" }} src={require("assets/img/twitter.png")} alt="Logo Twitter" width="40px"></img>
          </NavbarBrand>
          © {new Date().getFullYear()}, Designed by{'\u00A0'}
          <a
            href="https://www.sinfony.com.co/sinfony/"
            target="_blank"
          >
            SINFONY Todos los derechos reservados.
          </a>
        </div>
      </footer>
    </>
  );
}

export default FooterBlack;
